import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Card, Box, Button, Typography, CircularProgress } from '@mui/material'
import MaterialUITheme from '../../config/material-ui-theme'
import DataConTextField from '../common/data-con-form/FormFields'
import { OrganizationAPIService, UserAPIService } from '../../config/api-service'
import { APP_KEY, ORGANIZATIONS } from '../../config/constants'
import { useAlertContext } from '../../context/alert.context'
import SelectOrganization from './SelectOrganization'
import DataConnectorImage from '../../assets/images/dataconnector_main.png'
import './style.css'
import useScript from '../../config/useScript'

export const LoginComponent = () => {
  const [responseMessage, setResponseMessage] = useState('')
  const [organizations, setOrganizations] = useState([])
  const [email, setEmail] = useState('')
  const [selectionStep, setSelectionStep] = useState(false)
  const { errorAlert, warningAlert } = useAlertContext()
  const { REACT_APP_MARKETING_SITE = '//' } = process.env

  const history = useHistory()

  const queryParams = new URLSearchParams(window.location.search);
  const from = queryParams.get('from');

  useScript()

  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      EmailTXT: '',
      PasswordHASH: ''
    }
  })

  const submitOrganization = async (selectedOrganization = '', organizations = [], DataconnectorAdminFLG, InternalFLG) => {
    try {
      const assignedOrganization = await OrganizationAPIService.assignUserToOrganization(selectedOrganization)

      if (DataconnectorAdminFLG || InternalFLG) {
        assignedOrganization && localStorage.setItem(ORGANIZATIONS, JSON.stringify({
          organizations, selectedOrganization, hasPMethods: true, organizationTRL: null
        }))
      } else {
        const methods = await UserAPIService.getPaymentMethod()
        assignedOrganization && methods && localStorage.setItem(ORGANIZATIONS, JSON.stringify({
          organizations, selectedOrganization, hasPMethods: methods?.paymentMethods?.data?.length > 0 ? true : false, organizationTRL: assignedOrganization.organizationTRL
        }))
      }
      // !localStorage.getItem(APP_KEY) && localStorage.setItem(APP_KEY, JSON.stringify({ user }))
      history.push('/pipelines')
    } catch { }
  }

  const handleLogin = ({ EmailTXT, PasswordHASH }) => {
    localStorage.removeItem(APP_KEY)
    localStorage.removeItem(ORGANIZATIONS)
    setEmail(EmailTXT)
    UserAPIService.login({ EmailTXT, PasswordHASH })
      .then(json => {
        if (json && json.success) {
          setOrganizations(json?.organizationList || [])
          localStorage.setItem(APP_KEY, JSON.stringify({ user: json?.user, permissions: json?.permissionList }))
          submitOrganization(json?.user?.DefaultOrganizationID, json?.organizationList, json?.user?.DataconnectorAdminFLG, json?.organization?.InternalFLG);
          setSelectionStep(true)
        } else if (json.alert) {
          warningAlert(json.message)
        } else {
          errorAlert(json.message)
        }
      })
  }

  const createOrganization = () => {
    const { user } = JSON.parse(localStorage.getItem(APP_KEY))
    UserAPIService.addNewUserOrganization({ UserID: user.UserID, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then(res => {
        setOrganizations(res.organizationList)
      })
      .catch(err => { })
  }

  useEffect(() => {
    const getOrgs = async () => {
      try {
        OrganizationAPIService.getOrganizations().then(res => {
          setEmail(res?.user?.EmailTXT)
          setOrganizations(res?.organizationList || [])
          localStorage.setItem(APP_KEY, JSON.stringify({ user: res?.user, permissions: res?.permissionList }))
          submitOrganization(res?.user?.DefaultOrganizationID, res?.organizationList, res?.user?.DataconnectorAdminFLG, res?.organization?.InternalFLG);
          setSelectionStep(true)
        }).catch(err => { })
      } catch (err) {
        setResponseMessage(err)
      }
    }
    if (from === "marketing") {
      getOrgs()
    }
  }, [])

  return (
    <MaterialUITheme>
      {
        from === "marketing" && !selectionStep ? <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}>
          <CircularProgress />
        </div> : <>
            {selectionStep ?
              <Box>
                {!organizations?.length ? <Card raised={true} className='login'>
                  <div style={{ textAlign: 'center' }}>
                    <img src={DataConnectorImage} alt="Logo" style={{ maxWidth: 150 }} />
                  </div>
                  <Typography component={'div'} mt={2} fontSize="1.2rem" textAlign="center">
                    Hi, {email},<br />welcom back to Data Connector!
                  </Typography>
                  <Typography component={'div'} mt={2} fontSize="1.2rem" textAlign="center">
                    Looks like your email is not tied to an active<br />account. Please click below to start<br />your 14-Day free trial!
                  </Typography>
                  <Typography component={'div'} mt={3} mb={4} fontSize="1.2rem" textAlign="center">
                    If you need help, please reach out to us at<br />support@dataconnector.com
                  </Typography>
                  <Button fullWidth variant="contained" onClick={createOrganization}>Create new account</Button>
                </Card> : <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}>
                  <CircularProgress />
                </div>
                  // <Card raised={true} className='login'>
                  //   <div style={{ textAlign: 'center' }}>
                  //     <img src={DataConnectorImage} alt="Logo" style={{ maxWidth: 150 }} />
                  //   </div>
                  //   <h2 className="loginLabel">Select Organization</h2>
                  //   <Box component={'form'} noValidate autoComplete="off">
                  //     <SelectOrganization organizations={organizations} />
                  //   </Box>
                  // </Card> 
                }
              </Box> :
              <Card raised={true} className='login'>
                <div style={{ textAlign: 'center' }}>
                  <img src={DataConnectorImage} alt="Logo" style={{ maxWidth: 150 }} />
                </div>
                <h2 className="loginLabel">Sign In</h2>

                <Box component={'form'} noValidate autoComplete="off">

                  <DataConTextField
                    size="small"
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    name="EmailTXT"
                    control={control}
                    rules={{ required: `Email is required` }}
                    sx={{ mb: 2 }}
                  />
                  <DataConTextField
                    size="small"
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type="password"
                    name="PasswordHASH"
                    control={control}
                    rules={{ required: `Password is required` }}
                  />
                  <p style={{ textAlign: 'center', color: "red" }}>{responseMessage}</p>
                  <div className="forgot">
                    <Link to="/forgot" >Forgot Password</Link>
                  </div>
                  <Button fullWidth type="submit" variant="contained" onClick={handleSubmit(handleLogin)}>Submit</Button>
                </Box>
              </Card>
            }</>
      }

    </MaterialUITheme>
  )
}
