import React, { useContext, useEffect, createContext, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

const AlertContext = createContext();

export function useAlertContext() {
    return useContext(AlertContext);
}

export default function AlertProvider({ children }) {
    const [alerts, setAlerts] = useState([]);

    const handleClose = (index) => (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlerts((prevAlerts) => prevAlerts.filter((_, i) => i !== index));
    };

    const addAlert = (message, severity = 'error') => {
        setAlerts((prevAlerts) => [...prevAlerts, { message, severity }]);
    };

    const successAlert = (message) => {
        addAlert(message, 'success');
    };

    const warningAlert = (message) => {
        addAlert(message, 'warning');
    };

    const errorAlert = (message) => {
        addAlert(message, 'error');
    };

    useEffect(() => {
        if (alerts.length > 0) {
            const timer = setTimeout(() => {
                setAlerts((prevAlerts) => prevAlerts.slice(1));
            }, 15000);
            return () => clearTimeout(timer);
        }
    }, [alerts]);

    return (
        <AlertContext.Provider value={{ addAlert, successAlert, warningAlert, errorAlert }}>
            {children}
            {alerts.map((alert, index) => (
                <Snackbar
                    key={index}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={true}
                    autoHideDuration={15000}
                    onClose={handleClose(index)}
                >
                    <Alert severity={alert.severity} onClose={handleClose(index)}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            ))}
        </AlertContext.Provider>
    );
}