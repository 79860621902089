import { useState } from 'react'
import { useHistory } from 'react-router'
import { TextField, Button, Card } from '@mui/material'
import MaterialUITheme from '../../config/material-ui-theme'
import { useAlertContext } from '../../context/alert.context'
import { UserAPIService } from '../../config/api-service'
import { handleError } from '../../config/api-response'
import DataConnectorImage from '../../assets/images/dataconnector_main.png'

export default function ResetPasswordComponent({ email }) {
    const [code, setCode] = useState("")
    const [password, setPassword] = useState("")
    const [verifyPassword, setVerifyPassword] = useState("")
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formErrorMessage, setFormErrorMessage] = useState("")
    const history = useHistory()
    const { successAlert } = useAlertContext()

    const handleSubmit = (event) => {
        event.preventDefault()

        setFormSubmitted(true)

        // Validate email field has a value
        if (!code || code === "" || !password || password === "" || !verifyPassword || verifyPassword === "")
            return

        UserAPIService.resetForgottenPassword({ code, password, email })
            .then(res => {
                successAlert(res.message)
                history.push('/login')
            })
            .catch(err => handleError(err, "Form", setFormErrorMessage))
    }
    return (
        <MaterialUITheme>
            <Card raised={true} className='login'>
                <div style={{ textAlign: 'center' }}>
                    <img src={DataConnectorImage} alt="Logo" style={{ maxWidth: 150 }} />
                </div>

                <h2 className="loginLabel">Reset Password</h2>
            <form noValidate onSubmit={handleSubmit} >
                <TextField
                    size="small"
                    className="field"
                    id="username"
                    name="username"
                    label="Verification Code"
                    variant="outlined"
                    type="text"
                    value={code}
                    error={!code && formSubmitted}
                    onChange={e => setCode(e.target.value)} />
            
                <TextField
                    size="small"
                    className="field"
                    id="username"
                    name="username"
                    label="New Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    error={!password && formSubmitted}
                    onChange={e => setPassword(e.target.value)} />
            
                <TextField
                    size="small"
                    className="field"
                    id="username"
                    name="username"
                    label="Verify Password"
                    variant="outlined"
                    type="password"
                    value={verifyPassword}
                    error={!verifyPassword && formSubmitted}
                    onChange={e => setVerifyPassword(e.target.value)} />
                <div className="error text margin-1">{formErrorMessage}</div>
                <div className="row">
                    <Button fullWidth={true} type="submit" variant="contained" color="primary">Submit</Button>
                </div>
            </form>
            </Card>
        </MaterialUITheme>
    )
}
