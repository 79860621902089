import { Checkbox, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useConnectorModalContext } from "../../connectors.context";

const ObjectLoader = () => {
	const { selectedConnector } = useConnectorModalContext();

	return (
		<div>
			{/* Skeleton loader for the checkbox */}
			<Skeleton
				variant="rectangular"
				height={40}
				width="100%"
				sx={{
					borderRadius: "5px",
					mb: 1,
					display: "flex",
					justifyContent: "center",
				}}
			/>

			{/* Loading text */}
			<Typography
				sx={{
					textAlign: "left",
					wordBreak: "break-word",
					fontSize: { xs: "12px", md: "14px" },
					color: "var(--data-con-blue)",
					mb: 1,
				}}
			>
				Retrieving the{" "}
				{selectedConnector?.ConnectorNM?.toLowerCase() || "connector"}{" "}
				object information...
			</Typography>

			{[...Array(0)].map((_, index) => (
				<Skeleton
					key={index}
					variant="rounded"
					height={40}
					width="100%"
					sx={{ borderRadius: "5px", mb: 1 }}
				/>
			))}
		</div>
	);
};

export default ObjectLoader;
