import {
	Box,
	CardMedia,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Skeleton,
	TextField,
	Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../../config/api-service";
import { useConnectorModalContext } from "../../connectors.context";
import { sectionStatusImageMapper } from "../ConnectorInfo";
import ApiGeneralInfoLoader from "./ApiGeneralInfoLoader";

const changeColor = (defaultInput, userInput) => {
	return userInput !== null && userInput !== defaultInput;
};

const ApiGeneralInfo = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [activeTask, setActiveTask] = useState(null);
	const [apiInfoStatus, setApiInfoStatus] = useState(null);
	const [imageRotation, setImageRotation] = useState(0);

	const {
		selectedConnector,
		apiGeneralInfo,
		setApiGeneralInfo,
		setActiveTaskList,
		activeTaskList,
		userInfo,
		sectionStatusList,
		isSectionStatusImageLoading,
		onFieldFocus,
		setOnFieldFocus,
	} = useConnectorModalContext();

	useEffect(() => {
		const task = activeTaskList?.find(
			(activeTask) => activeTask.prime_jira_task_id === 4
		);
		setActiveTask(task);
	}, [activeTaskList]);

	useEffect(() => {
		setIsLoading(true);

		ConnectorAPIService.getConnectorApiGeneralInfo(
			selectedConnector.ConnectorID
		)
			.then((response) => {
				setApiGeneralInfo(response.connectorApiGeneralInfo);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, setApiGeneralInfo]);

	useEffect(() => {
		setIsLoading(true);
		ConnectorAPIService.getConnectorOrchestratorEvent(
			selectedConnector.ConnectorID,
			"METADATA"
		)
			.then((response) => {
				setActiveTaskList(response.dataList[0]?.active_task_list);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, setApiGeneralInfo, setActiveTaskList]);

	// const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;

	const updateApiGeneralInfo = async () => {
		setApiGeneralInfo((prev) => ({
			...prev,
			[onFieldFocus.field]: onFieldFocus.value,
		}));

		try {
			await ConnectorAPIService.updateConnectorApiGeneralInfo(
				apiGeneralInfo.ConnectorApiGeneralInfoID,
				{
					...apiGeneralInfo,
					[onFieldFocus.field]: onFieldFocus.value,
					UpdatedByID: userInfo?.user?.UserID,
				}
			);
		} catch {}

		setOnFieldFocus({
			field: null,
			value: null,
		});
	};

	useEffect(() => {
		const apiInfo = sectionStatusList.find(
			(section) =>
				section?.field_mapping_table_nm === "connector_api_general_info"
		);
		apiInfo && setApiInfoStatus(apiInfo.section_status);
	}, [sectionStatusList]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setImageRotation(imageRotation + 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [imageRotation]);

	return (
		<>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
				{isSectionStatusImageLoading ? (
					<Grid>
						<Skeleton variant="circular" width={40} height={40} />
					</Grid>
				) : (
					sectionStatusImageMapper[apiInfoStatus] && (
						<Tooltip title={apiInfoStatus}>
							<CardMedia
								component={"img"}
								alt="status"
								image={sectionStatusImageMapper[apiInfoStatus]}
								sx={{
									width: 40,
									height: 40,
									rotate:
										apiInfoStatus === "In Progress"
											? `${imageRotation * 45}deg`
											: "0deg",
								}}
							/>
						</Tooltip>
					)
				)}

				<Divider
					sx={{
						textTransform: "uppercase",
						fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
						width: "100%",
					}}
				>
					API GENERAL INFO
				</Divider>
			</Box>

			{isLoading ? (
				<ApiGeneralInfoLoader />
			) : (
				<>
					<Grid container spacing={2} sx={{ py: 4 }}>
						<Grid item xs={12} md={6} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="API Documentation URL"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserApiDocumentationUrlTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserApiDocumentationUrlTxt ||
											  apiGeneralInfo?.ApiDocumentationUrlTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserApiDocumentationUrlTxt:
												e.target.value,
										}));
										setOnFieldFocus({
											field: "UserApiDocumentationUrlTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserApiDocumentationUrlTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.ApiDocumentationUrlTxt,
													apiGeneralInfo?.UserApiDocumentationUrlTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.ApiDocumentationUrlTxt,
												apiGeneralInfo?.UserApiDocumentationUrlTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={6} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									required
									disabled={activeTask}
									label="API GitHub URL"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserApiGithubUrlTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserApiGithubUrlTxt ||
											  apiGeneralInfo?.ApiGithubUrlTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserApiGithubUrlTxt: e.target.value,
										}));
										setOnFieldFocus({
											field: "UserApiGithubUrlTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserApiGithubUrlTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.ApiGithubUrlTxt,
													apiGeneralInfo?.UserApiGithubUrlTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.ApiGithubUrlTxt,
												apiGeneralInfo?.UserApiGithubUrlTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
									error={
										apiGeneralInfo?.UpdatedByID &&
										!apiGeneralInfo?.UserApiGithubUrlTxt
									}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									disabled={activeTask}
									label="Release Date"
									views={["day"]}
									value={
										(apiGeneralInfo?.UserApiReleaseDate &&
										moment(
											apiGeneralInfo.UserApiReleaseDate
										).isValid()
											? moment(
													apiGeneralInfo.UserApiReleaseDate
											  )
											: null) ||
										(apiGeneralInfo?.ApiReleaseDate &&
										moment(
											apiGeneralInfo.ApiReleaseDate
										).isValid()
											? moment(
													apiGeneralInfo.ApiReleaseDate
											  )
											: null)
									}
									onChange={async (newValue) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserApiReleaseDate: newValue,
										}));
										try {
											await ConnectorAPIService.updateConnectorApiGeneralInfo(
												apiGeneralInfo.ConnectorApiGeneralInfoID,
												{
													...apiGeneralInfo,
													UserApiReleaseDate:
														newValue.format(
															"YYYY-MM-DD"
														),
												}
											);
										} catch {}
									}}
									renderInput={(params) => (
										<Tooltip
											title={
												activeTask
													? `You can not update this field when the task: ${activeTask?.name} is in an active state`
													: ""
											}
											placement="top"
										>
											<TextField
												sx={{
													minWidth: "100%",
													".MuiOutlinedInput-notchedOutline":
														changeColor(
															apiGeneralInfo?.ApiReleaseDate,
															apiGeneralInfo?.UserApiReleaseDate
														) && {
															borderColor:
																"var(--data-con-blue)",
														},
												}}
												InputLabelProps={{
													sx: {
														color:
															changeColor(
																apiGeneralInfo?.ApiReleaseDate,
																apiGeneralInfo?.UserApiReleaseDate
															) &&
															"var(--data-con-blue)",
													},
												}}
												error={false}
												size="small"
												{...params}
												helperText={null}
											/>
										</Tooltip>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									disabled={activeTask}
									label="Last Update Date"
									views={["day"]}
									value={
										(apiGeneralInfo?.UserApiLastUpdateDate &&
										moment(
											apiGeneralInfo.UserApiLastUpdateDate
										).isValid()
											? moment(
													apiGeneralInfo.UserApiLastUpdateDate
											  )
											: null) ||
										(apiGeneralInfo?.ApiLastUpdateDate &&
										moment(
											apiGeneralInfo.ApiLastUpdateDate
										).isValid()
											? moment(
													apiGeneralInfo.ApiLastUpdateDate
											  )
											: null)
									}
									onChange={async (newValue) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserApiLastUpdateDate: newValue,
										}));
										try {
											await ConnectorAPIService.updateConnectorApiGeneralInfo(
												apiGeneralInfo.ConnectorApiGeneralInfoID,
												{
													...apiGeneralInfo,
													UserApiLastUpdateDate:
														newValue.format(
															"YYYY-MM-DD"
														),
												}
											);
										} catch {}
									}}
									renderInput={(params) => (
										<Tooltip
											title={
												activeTask
													? `You can not update this field when the task: ${activeTask?.name} is in an active state`
													: ""
											}
											placement="top"
										>
											<TextField
												sx={{
													minWidth: "100%",
													".MuiOutlinedInput-notchedOutline":
														changeColor(
															apiGeneralInfo?.ApiLastUpdateDate,
															apiGeneralInfo?.UserApiLastUpdateDate
														) && {
															borderColor:
																"var(--data-con-blue)",
														},
												}}
												InputLabelProps={{
													sx: {
														color:
															changeColor(
																apiGeneralInfo?.ApiLastUpdateDate,
																apiGeneralInfo?.UserApiLastUpdateDate
															) &&
															"var(--data-con-blue)",
													},
												}}
												error={false}
												size="small"
												{...params}
												helperText={null}
											/>
										</Tooltip>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Support Contact Email"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserSupportContactEmailTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserSupportContactEmailTxt ||
											  apiGeneralInfo?.SupportContactEmailTxt
									}
									// error={
									// 	!emailReg.test(apiGeneralInfo?.email) &&
									// 	(apiGeneralInfo?.email !== "" ||
									// 		apiGeneralInfo?.email !== undefined)
									// }
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserSupportContactEmailTxt:
												e.target.value,
										}));
										setOnFieldFocus({
											field: "UserSupportContactEmailTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserSupportContactEmailTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.SupportContactEmailTxt,
													apiGeneralInfo?.UserSupportContactEmailTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.SupportContactEmailTxt,
												apiGeneralInfo?.UserSupportContactEmailTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Support Contact Phone"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserSupportContactPhoneTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserSupportContactPhoneTxt ||
											  apiGeneralInfo?.SupportContactPhoneTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserSupportContactPhoneTxt:
												e.target.value,
										}));
										setOnFieldFocus({
											field: "UserSupportContactPhoneTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserSupportContactPhoneTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.SupportContactPhoneTxt,
													apiGeneralInfo?.UserSupportContactPhoneTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.SupportContactPhoneTxt,
												apiGeneralInfo?.UserSupportContactPhoneTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Terms of Service URL"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserTermsOfServiceUrlTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserTermsOfServiceUrlTxt ||
											  apiGeneralInfo?.TermsOfServiceUrlTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserTermsOfServiceUrlTxt:
												e.target.value,
										}));
										setOnFieldFocus({
											field: "UserTermsOfServiceUrlTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserTermsOfServiceUrlTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.TermsOfServiceUrlTxt,
													apiGeneralInfo?.UserTermsOfServiceUrlTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.TermsOfServiceUrlTxt,
												apiGeneralInfo?.UserTermsOfServiceUrlTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Privacy Policy URL"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserPrivacyPolicyUrlTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserPrivacyPolicyUrlTxt ||
											  apiGeneralInfo?.PrivacyPolicyUrlTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserPrivacyPolicyUrlTxt:
												e.target.value,
										}));
										setOnFieldFocus({
											field: "UserPrivacyPolicyUrlTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserPrivacyPolicyUrlTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.PrivacyPolicyUrlTxt,
													apiGeneralInfo?.UserPrivacyPolicyUrlTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.PrivacyPolicyUrlTxt,
												apiGeneralInfo?.UserPrivacyPolicyUrlTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<FormControl
								size="small"
								sx={{
									minWidth: "100%",
									maxWidth: "100%",
								}}
							>
								<InputLabel
									id="Supported-Environments"
									sx={{
										fontSize: "0.875rem",
										textAlign: "left",
										color:
											changeColor(
												apiGeneralInfo?.SupportEnvironmentsDSC,
												apiGeneralInfo?.UserSupportEnvironmentsDSC
											) && "var(--data-con-blue)",
									}}
								>
									Supported Environments
								</InputLabel>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<Select
										disabled={activeTask}
										labelId="Supported-Environments"
										multiple
										renderValue={(selected) =>
											selected.join(", ")
										}
										value={JSON.parse(
											apiGeneralInfo?.UserSupportEnvironmentsDSC ||
												apiGeneralInfo?.SupportEnvironmentsDSC ||
												"[]"
										)}
										onChange={async (e) => {
											setApiGeneralInfo((prev) => ({
												...prev,
												UserSupportEnvironmentsDSC:
													JSON.stringify(
														e.target.value
													),
											}));

											try {
												await ConnectorAPIService.updateConnectorApiGeneralInfo(
													apiGeneralInfo.ConnectorApiGeneralInfoID,
													{
														...apiGeneralInfo,
														UserSupportEnvironmentsDSC:
															JSON.stringify(
																e.target.value
															),
													}
												);
											} catch {}
										}}
										label="Supported Environments"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													apiGeneralInfo?.SupportEnvironmentsDSC,
													apiGeneralInfo?.UserSupportEnvironmentsDSC
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									>
										<MenuItem value={"Production"}>Production</MenuItem>
										<MenuItem value={"Sandbox"}>Sandbox</MenuItem>
										<MenuItem value={"Development"}>
											Development
										</MenuItem>
										<MenuItem value={"Testing"}>Testing</MenuItem>
									</Select>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<FormControl
								size="small"
								sx={{
									minWidth: "100%",
									maxWidth: "100%",
								}}
							>
								<InputLabel
									id="Supported-Data-Formats"
									sx={{
										fontSize: "0.875rem",
										textAlign: "left",
										color:
											changeColor(
												apiGeneralInfo?.SupportedDataFormatsTxt,
												apiGeneralInfo?.UserSupportedDataFormatsTxt
											) && "var(--data-con-blue)",
									}}
								>
									Supported Data Formats
								</InputLabel>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<Select
										disabled={activeTask}
										labelId="Supported-Data-Formats"
										multiple
										renderValue={(selected) =>
											selected.join(", ")
										}
										value={JSON.parse(
											apiGeneralInfo?.UserSupportedDataFormatsTxt ||
												apiGeneralInfo?.SupportedDataFormatsTxt ||
												"[]"
										)}
										onChange={async (e) => {
											setApiGeneralInfo((prev) => ({
												...prev,
												UserSupportedDataFormatsTxt:
													JSON.stringify(
														e.target.value
													),
											}));

											try {
												await ConnectorAPIService.updateConnectorApiGeneralInfo(
													apiGeneralInfo.ConnectorApiGeneralInfoID,
													{
														...apiGeneralInfo,
														UserSupportedDataFormatsTxt:
															JSON.stringify(
																e.target.value
															),
													}
												);
											} catch {}
										}}
										label="Supported Data Formats"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													apiGeneralInfo?.SupportedDataFormatsTxt,
													apiGeneralInfo?.UserSupportedDataFormatsTxt
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									>
										<MenuItem value={"JSON"}>JSON</MenuItem>
										<MenuItem value={"XML"}>XML</MenuItem>
									</Select>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="License Type"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserLicenseTypeTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserLicenseTypeTxt ||
											  apiGeneralInfo?.LicenseTypeTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserLicenseTypeTxt: e.target.value,
										}));
										setOnFieldFocus({
											field: "UserLicenseTypeTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserLicenseTypeTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.LicenseTypeTxt,
													apiGeneralInfo?.UserLicenseTypeTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.LicenseTypeTxt,
												apiGeneralInfo?.UserLicenseTypeTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Version Change Log URL"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserVersionChangeLogUrlTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserVersionChangeLogUrlTxt ||
											  apiGeneralInfo?.VersionChangeLogUrlTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserVersionChangeLogUrlTxt:
												e.target.value,
										}));
										setOnFieldFocus({
											field: "UserVersionChangeLogUrlTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserVersionChangeLogUrlTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.VersionChangeLogUrlTxt,
													apiGeneralInfo?.UserVersionChangeLogUrlTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.VersionChangeLogUrlTxt,
												apiGeneralInfo?.UserVersionChangeLogUrlTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Community Support URL"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserCommunitySupportUrlTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserCommunitySupportUrlTxt ||
											  apiGeneralInfo?.CommunitySupportUrlTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserCommunitySupportUrlTxt:
												e.target.value,
										}));
										setOnFieldFocus({
											field: "UserCommunitySupportUrlTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserCommunitySupportUrlTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.CommunitySupportUrlTxt,
													apiGeneralInfo?.UserCommunitySupportUrlTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.CommunitySupportUrlTxt,
												apiGeneralInfo?.UserCommunitySupportUrlTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<TextField
									disabled={activeTask}
									label="Known Issues URL"
									variant="outlined"
									size="small"
									value={
										onFieldFocus.field ===
										"UserKnownIssuesUrlTxt"
											? onFieldFocus.value
											: apiGeneralInfo?.UserKnownIssuesUrlTxt ||
											  apiGeneralInfo?.KnownIssuesUrlTxt
									}
									onChange={(e) => {
										setApiGeneralInfo((prev) => ({
											...prev,
											UserKnownIssuesUrlTxt:
												e.target.value,
										}));
										setOnFieldFocus({
											field: "UserKnownIssuesUrlTxt",
											value: e.target.value,
										});
									}}
									onBlur={updateApiGeneralInfo}
									onFocus={(e) => {
										setOnFieldFocus({
											field: "UserKnownIssuesUrlTxt",
											value: e.target.value,
										});
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color:
												changeColor(
													apiGeneralInfo?.KnownIssuesUrlTxt,
													apiGeneralInfo?.UserKnownIssuesUrlTxt
												) && "var(--data-con-blue)",
										},
									}}
									InputProps={{
										sx: {
											height: 37,
											padding: 1,
										},
										inputProps: {
											sx: {
												padding: 0,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										".MuiOutlinedInput-notchedOutline":
											changeColor(
												apiGeneralInfo?.KnownIssuesUrlTxt,
												apiGeneralInfo?.UserKnownIssuesUrlTxt
											) && {
												borderColor:
													"var(--data-con-blue)",
											},
									}}
								/>
							</Tooltip>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default ApiGeneralInfo;
